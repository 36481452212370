<template>
    <div>
        <div class="register">
            <h4>[ 机构注册 ]</h4>
            <div class="four_stap">
                <div>
                    <div :class="registerShow>0?'active_bg':''">1</div>
                    <p>填写机构信息</p>
                </div>
                <div>
                    <div :class="registerShow>1?'active_bg':''">2</div>
                    <p>填写用户信息</p>
                </div>
                <div>
                    <div :class="registerShow>2?'active_bg':''">3</div>
                    <p>验证机构/用户信息</p>
                </div>
                <div>
                    <div :class="registerShow>3?'active_bg':''">4</div>
                    <p>提交审核</p>
                </div>
            </div>
            <!-- 第一步 -->
            <div class="stap1_biaodan" v-show="registerShow==1">
                <div>
                    <el-form ref="form" :model="form1" label-width="140px">
                        <el-form-item label="统一社会信用代码：">
                            <el-input onkeyup="value=value.replace(/[\u4e00-\u9fa5]/ig,'')" v-model="form1.data1"
                                      placeholder="请输入统一社会信用代码" size="mini"></el-input>
                        </el-form-item>
                        <el-form-item label="机构名称：">
                            <el-input v-model="form1.data2" placeholder="请输入机构名称" size="mini"></el-input>
                        </el-form-item>
                        <el-form-item label="法定代表人：">
                            <el-input v-model="form1.data3" placeholder="请输入法定代表人" size="mini"></el-input>
                        </el-form-item>
                        <el-form-item label="联系电话：">
                            <el-input @input="checkMobile" onkeyup="value=value.replace(/[\u4e00-\u9fa5]/ig,'')"
                                      v-model="form1.data4" placeholder="请输入联系电话" size="mini"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="step2_right">
                    <el-form ref="form" :model="form" label-width="130px" style="margin-left: -30px">
                        <el-form-item label="办公地址：">
                            <el-input v-model="form1.data5" placeholder="请输入办公地址" size="mini"></el-input>
                        </el-form-item>
                        <el-form-item label="邮箱：">
                            <el-input onkeyup="value=value.replace(/[\u4e00-\u9fa5]/ig,'')" v-model="form1.data6"
                                      placeholder="请输入邮箱" size="mini"></el-input>
                        </el-form-item>
                        <el-form-item label="成立时间：">
                            <el-date-picker
                                    v-model="form1.data7"
                                    type="date"
                                    size="mini"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="机构类型：">
                            <el-select v-model="form1.data8" placeholder="请选择机构类型" size="mini">
                                <el-option :label="item.label" :value="item.value" v-for="(item,index) in jglxList"
                                           :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </div>
            </div>

            <!-- 第二步 -->
            <div class="stap1_biaodan" v-show="registerShow==2">
                <div style="display: flex;justify-content: center">
                    <el-form ref="form" :model="form2" label-width="130px" style="margin-left: -30px">
						<el-form-item label="姓名:">
						    <el-input v-model="form2.data3" size="mini" placeholder="请输入姓名"></el-input>
						</el-form-item>
                        <el-form-item label="用户名:">
                            <el-input v-model="form2.data1" size="mini" placeholder="请输入用户名"></el-input>
                        </el-form-item>
                        <el-form-item label="密码:">
                            <el-input v-model="form2.data2" type="password" size="mini" placeholder="请输入密码"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <!-- 第三步 -->
            <div class="stap3_biaodan" v-show="registerShow==3">
                <div class="stap3_button">
                    <el-form ref="form" :model="form3" label-width="130px" style="margin-left: -30px">
                        <el-form-item label="联系电话:">
                            <el-input v-model="form3.data1" size="mini" style="width: 260px;"
                                      placeholder="请输入联系电话"></el-input>
                        </el-form-item>
                        <el-form-item label="短信验证码:">
                            <el-input v-model="form3.data2" size="mini" style="width: 150px"
                                      placeholder="请输入短信验证码"></el-input>
                        </el-form-item>
                        <!--                        <el-form-item label="请输入验证码:">-->
                        <!--                            <el-input v-model="form3.data3" size="mini" placeholder="请输入验证码"></el-input>-->
                        <!--                        </el-form-item>-->
                        <button @click.prevent="getYzm" v-show="show" style="color: red;">获取验证码</button>
                        <button @click.prevent="getYzm" v-show="!show">重新发送（{{count}}）</button>
                    </el-form>
                </div>

            </div>
            <!-- 第四步 -->
            <div class="stap4_biaodan" v-show="registerShow==4">
                <div class="stap4_content">
                    <img src="@/assets/images/register/wancheng.png">
                    <p>注册完成，请等待审核。
                        <!--                        <span @click="toLogin">登录</span>-->
                    </p>
                </div>
            </div>
            <div class="prvOrNext" v-show="registerShow!=4&&registerShow!=1">
                <button @click="prv">上一步</button>
                <button @click="next()">下一步</button>
            </div>
            <button class="next" @click="next" v-show="registerShow==1">下一步</button>
            <p class="alread_name" v-show="registerShow!=4">已有账户，直接<span @click="toLogin"
                                                                         class="toLoginSmall">登录</span>
            </p>
        </div>
    </div>
</template>

<script>
    import MathUuid from "../../assets/js/Math.uuid"

    export default {
        name: "register",
        data() {
            return {
                url: this.$Config.base_server,
                show: true,
                count: '',
                TIME_COUNT: 60,//验证码倒计时60s
                registerShow: 1,
                form1: {
                    data1: '',
                    data2: '',
                    data3: '',
                    data4: '',
                    data5: '',
                    data6: '',
                    data7: '',
                    data8: '',
                },
                form2: {
                    data1: '',
                    data2: '',
                    data3: '',
                    data4: '',
                },
                form3: {
                    data1: '',
                    data2: '',
                    data3: ''
                },
                form: {
                    name: ''
                },
                jglxList: [],
                key: '',
            }
        },
        mounted() {
            this.jigouClass();
        },
        methods: {
            checkMobile() {
                this.form1.data4 = this.form1.data4.replace(/[^\d]/g, '')
            },
            // 验证码倒计时60s
            getCode(formData) {
                if (!this.timer) {
                    this.count = this.TIME_COUNT;
                    this.show = false;
                    this.timer = setInterval(() => {
                        if (this.count > 0 && this.count <= this.TIME_COUNT) {
                            this.count--;
                        } else {
                            this.show = true;
                            clearInterval(this.timer);
                            this.timer = null;
                        }
                    }, 1000)
                }
            },
            getYzm() {//获取验证码
                this.getCode();
                const that = this;

                const client_id = localStorage.getItem("client_id");

                $.post(this.url + "/api-sms/sms-internal/codes?phone=" + this.form3.data1+"&client_id="+client_id, {}, function (res) {

                    that.key = res.key;
                });
            },
            jigouClass() {
                const that = this;

                const data = {
                    access_token: localStorage.getItem("token")
                }
                var client_id=localStorage.getItem("client_id");
                $.get(this.url + "/api-public/sysdict/list?key=org_type&client_id="+client_id, {}, function (res) {
                    that.jglxList = res;
                });
            },
            register() {
                const that = this;
                //表一
                //17394362580
                //3055716384@qq.com
                const opeorg = {
                    orgCard: this.form1.data1, //机构编号
                    orgName: this.form1.data2, //机构名称
                    legPerson: this.form1.data3, //法人代表
                    phone: this.form1.data4, //机构联系电话
                    busAddress: this.form1.data5, //办公地址
                    mail: this.form1.data6, //机构邮箱
                    estTime: this.form1.data7, //机构成立时间
                    type: this.form1.data8 //机构类型
                };
                //    表二
                const opeuser = {
                    username: this.form2.data1, //机构用户名
                    name: this.form2.data3, //机构用户姓名
                    password: this.form2.data2, //机构用户密码
                    phone: this.form3.data1, //机构用户联系方式
                    newPassword: this.form2.data2,
                    type: this.form1.data8//用户类型
                }
                var client_id=localStorage.getItem("client_id");
                var list = {
                    opeOrg: opeorg,
                    opeUser: opeuser,
                    smsCode: this.form3.data2,
                    key: this.key
                };
                $.ajax({
                    url: this.url + "/api-public/common/register?client_id="+client_id,
                    contentType: 'application/json;charset=utf8',
                    data: JSON.stringify(list),
                    type: 'post',
                    success: function (result) {

                        if (result.resp_code == 0) { //注册成功
                            that.$message({
                                showClose: true,
                                dangerouslyUseHTMLString: true,
                                message: result.resp_msg,
                                type: 'success'
                            });
                            that.registerShow += 1;
                        } else {
                            that.$message({
                                showClose: true,
                                dangerouslyUseHTMLString: true,
                                message: result.resp_msg,
                                type: 'error'
                            });
                        }

                    }
                });

            },
            prv() {
                this.registerShow -= 1;
            },
            next() {
                if (this.registerShow == 1) {
                    //验证第一步是否为空
                    if (this.validateForm1() == false) {
                        return;
                    }
                    this.registerShow += 1;
                } else if (this.registerShow == 2) {
                    //验证第二步是否为空
                    if (this.validateForm2() == false) {
                        return;
                    }
                    this.registerShow += 1;
                } else if (this.registerShow == 3) {
                    //验证第二步是否为空
                    if (this.validateForm3() == false) {
                        return;
                    }
                    this.register();
                }


            },
            validateForm1() {
                if (this.form1.data1 == '') {
                    this.$message({
                        showClose: true,
                        message: '请您输入统一社会信用代码',
                        type: 'warning'
                    });
                    return false;
                } else if (!this.enterCodeCheck(this.form1.data1)) {
                    this.$message({
                        showClose: true,
                        message: '请输入正确的统一社会信用代码',
                        type: 'warning'
                    });
                    return false;
                }
                if (this.form1.data2 == '') {
                    this.$message({
                        showClose: true,
                        message: '请您输入机构名称',
                        type: 'warning'
                    });
                    return false;
                } else if (this.form1.data3 == '') {
                    this.$message({
                        showClose: true,
                        message: '请您输入法定代表人',
                        type: 'warning'
                    });
                    return false;
                } else if (this.form1.data4 == '') {
                    this.$message({
                        showClose: true,
                        message: '请您输入联系电话',
                        type: 'warning'
                    });
                    return;
                } else if (this.form1.data5 == '') {
                    this.$message({
                        showClose: true,
                        message: '请您输入办公地址',
                        type: 'warning'
                    });
                    return false;
                } else if (this.form1.data6 == '') {
                    this.$message({
                        showClose: true,
                        message: '请您输入邮箱',
                        type: 'warning'
                    });
                    return false;
                } else if (this.form1.data7 == '') {
                    this.$message({
                        showClose: true,
                        message: '请您选择成立时间',
                        type: 'warning'
                    });
                    return false;
                } else if (this.form1.data8 == '') {
                    this.$message({
                        showClose: true,
                        message: '请您选择机构类型',
                        type: 'warning'
                    });
                    return false;
                }
                return true;
            },
            validateForm2() {
                if (this.form2.data1 == '') {
                    this.$message({
                        showClose: true,
                        message: '请您输入用户名',
                        type: 'warning'
                    });
                    return false;
                } else if (this.form2.data2 == '') {
                    this.$message({
                        showClose: true,
                        message: '请您输入密码',
                        type: 'warning'
                    });
                    return false;
                } else if (this.form2.data3 == '') {
                    this.$message({
                        showClose: true,
                        message: '请您输入姓名',
                        type: 'warning'
                    });
                    return false;
                }
                return true;
            },
            validateForm3() {
                if (this.form3.data1 == '') {
                    this.$message({
                        showClose: true,
                        message: '请您输入联系电话',
                        type: 'warning'
                    });
                    return false;
                } else if (this.form3.data2 == '') {
                    this.$message({
                        showClose: true,
                        message: '请您输入短信验证码',
                        type: 'warning'
                    });
                    return false;
                }
                return true;
            },
            toLogin() {
                this.$emit("showRegisterMethods", false)
                //
            }, enterCodeCheck(Code) {
                var patrn = /^[0-9A-Z]+$/;
                //18位校验及大写校验
                if ((Code.length != 18) || (patrn.test(Code) == false)) {
                    console.info("不是有效的统一社会信用编码！");
                    return false;
                } else {
                    var Ancode;//统一社会信用代码的每一个值
                    var Ancodevalue;//统一社会信用代码每一个值的权重
                    var total = 0;
                    var weightedfactors = [1, 3, 9, 27, 19, 26, 16, 17, 20, 29, 25, 13, 8, 24, 10, 30, 28];//加权因子
                    var str = '0123456789ABCDEFGHJKLMNPQRTUWXY';
                    //不用I、O、S、V、Z
                    for (var i = 0; i < Code.length - 1; i++) {
                        Ancode = Code.substring(i, i + 1);
                        Ancodevalue = str.indexOf(Ancode);
                        total = total + Ancodevalue * weightedfactors[i];
                        //权重与加权因子相乘之和
                    }
                    var logiccheckcode = 31 - total % 31;
                    if (logiccheckcode == 31) {
                        logiccheckcode = 0;
                    }
                    var Str = "0,1,2,3,4,5,6,7,8,9,A,B,C,D,E,F,G,H,J,K,L,M,N,P,Q,R,T,U,W,X,Y";
                    var Array_Str = Str.split(',');
                    logiccheckcode = Array_Str[logiccheckcode];
                    var checkcode = Code.substring(17, 18);
                    if (logiccheckcode != checkcode) {
                        console.info("不是有效的统一社会信用编码！");
                        return false;
                    } else {
                        console.info("yes");
                    }
                    return true;
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .el-input, .el-select {
        width: 200px;
    }

    .stap3_button {
        position: relative;

        button {
            position: absolute;
            top: 53px;
            right: 275px;
            width: 100px;
            height: 27px;
            font-size: 13px;
            color: #4DA5E7;
            border: 1px solid #4DA5E7;
            outline: none;
            background: none;
            cursor: pointer;
        }
    }

    .el-form-item {
        margin-bottom: 5px;
    }
</style>
