<template>
    <div>
        <div>
            <p style="text-align:center;font-size:22px;letter-spacing:2px;font-family:微软雅黑;margin-bottom:15px">业主维修资金查询</p>
        </div>
        <div class="table_content" style="padding:25px 15px 109px">
            <div class="search" style="padding:0">
                <el-form ref="form" :model="form" label-width="80px" :inline="true">
                <el-form-item label="身份证号:">
                    <el-input v-model="form.idCard" size="mini" placeholder="请输入身份证号"></el-input>
                </el-form-item>
                    <button @click.prevent="common()" style="cursor: pointer;">查询</button>
                    <button @click.prevent="back()" style="cursor: pointer;">返回</button>
                </el-form>
            </div>
            <!--表格-->
            <template>
                <el-card>
                    <el-table
                        ref="multipleTable"
                        :data="tableData"
                        tooltip-effect="dark"
                        style="width: 100%"
                        stripe
                        v-loading="loading"
                        element-loading-text="拼命加载中"
                        @selection-change="handleSelectionChange"
                    >
                        <el-table-column property="villageName" label="小区名称" show-overflow-tooltip></el-table-column>
                        <el-table-column property="towerNum" label="楼幢号" show-overflow-tooltip></el-table-column>
                        <el-table-column property="unitName" label="单元名称" show-overflow-tooltip></el-table-column>
                        <el-table-column property="houseNum" label="户室号" show-overflow-tooltip></el-table-column>
                        <el-table-column property="ownerName" label="业主姓名" show-overflow-tooltip></el-table-column>
                        <el-table-column property="houseAcctBal" label="账户余额" show-overflow-tooltip></el-table-column>
                        <el-table-column property="interest" label="利息" show-overflow-tooltip></el-table-column>
                    </el-table>
                </el-card>
            </template>
        </div>
    </div>
</template>
<script>
export default {
    name: "YZQuery",
    data() {
        return {
            url: this.$Config.base_server,
            form: {
                idCard: ""
            },
            tableData: [],
            multipleSelection: [],
            loading: false,
            data: {
            
            }
        };
    },
    methods: {
        common(currentPage = 0) {
            const that = this;
            const client_id = localStorage.getItem("client_id");
            //搜索框的值
            that.data.idCard = that.form.idCard;
            const reg = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
            if(that.data.idCard==''||that.data.idCard==undefined||that.data.idCard==null){
                this.$message({
                    message: `身份证号不能为空`,
                    type: 'error'
                });
                return false;
            }else{
                if((!reg.test(that.data.idCard)) && that.data.idCard != ''){
                    this.$message({
                        message: `身份证号格式不正确`,
                        type: 'error'
                    });
                    return false;
                }else{
                    that.loading = true;
                    $.ajax({
                        url: this.url + "/api-public/yzQuery/list?client_id="+client_id,
                        data: that.data,
                        contentType: "application/json",
                        type: "get",
                        success: function(res) {
                            that.loading = false;
                            if (res.length > 0) {
                                that.tableData = res;
                            }
                        },
                        error: function(res) {
                            that.loading = false;
                        }
                    });
                }
            }
            
        },
        handleCurrentChange(val) {
            //当前页数改变
            this.common(val);
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        back(){
            //返回按钮
            this.$router.go(-1);
        },

    }
};
</script>