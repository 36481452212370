<template>
  <div>
    <div class="content">
      <!-- 轮播部分 -->
      <el-row class="login">
        <el-col :span="3">
          <div class="login_left">
            <div :class="index==1?'active':''" @click="tab(1)">
              <div v-if="index==1">
                <img src="@/assets/images/kaifashang.png" alt />
              </div>
              <p>开发商登录</p>
            </div>
            <div @click="tab(2)" :class="index==2?'active':''">
              <div v-if="index==2">
                <img src="@/assets/images/home.png" alt />
              </div>
              <p>物业登录</p>
            </div>
            <div @click="tab(3)" :class="index==3?'active':''">
              <div v-if="index==3">
                <img src="@/assets/images/self_home.png" alt />
              </div>
              <p style="margin-top: 1px;">
                业主委员会
                <br />登录
              </p>
            </div>
            <!--<div @click="tab(4)" :class="index==4?'active':''">
              <div v-if="index==4">
                <img src="@/assets/images/self.png" alt />
              </div>
              <p>业主登录</p>
            </div>-->
          </div>
        </el-col>
        <el-col :span="12" v-show="showRegister==false">
          <div class="login_center">
            <div class="swiper-container">
              <div class="swiper-wrapper">
                <div class="swiper-slide pic1">
<!--                  <p>2020业主委员会投票结果公示</p>-->
                </div>
                <div class="swiper-slide pic2">
<!--                  <p>2020业主委员会投票结果公示</p>-->
                </div>
                <div class="swiper-slide pic3">
<!--                  <p>2020业主委员会投票结果公示</p>-->
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
          </div>
        </el-col>
        <el-col :span="9" v-show="showRegister==false">
          <div class="login_right" v-show="ifShowPasswordLogin">
            <h5>
              <span
                      :class="mima_login_tab1==1?'dengLuClass_active':''"
                      @click="mima_login_tab(1)"
              >账号登录</span>
              <!--<span
                @click="mima_login_tab(3)"
                :class="mima_login_tab1==3?'dengLuClass_active':''"
              >业主注册</span>-->
            </h5>
            <div v-show="mima_login_tab1==1">
              <div>
                <img src="@/assets/images/wo.png" />
              </div>
              <div>
                <input
                        v-model="loginParams.userName"
                        maxlength="19"
                        type="text"
                        placeholder="用户名"
                        @keyup.enter="login"
                />
              </div>
            </div>
            <div v-show="mima_login_tab1==1">
              <div>
                <img src="@/assets/images/suo.png" />
              </div>
              <div>
                <input
                        v-model="loginParams.password"
                        style="margin-left: 3px"
                        maxlength="19"
                        type="password"
                        placeholder="密码"
                        @keyup.enter="login"
                />
              </div>
            </div>

            <div style="width: 177px;" v-show="mima_login_tab1==1">
              <div>
                <img src="@/assets/images/anquan.png" />
              </div>
              <div>
                <input
                        v-model="loginParams.yzm"
                        maxlength="4"
                        type="text"
                        onkeyup="value=value.replace(/[\u4e00-\u9fa5]/ig,'')"
                        placeholder="验证码"
                        style="width: 120px;"
                        class="layui-input"
                        @keyup.enter="login"
                />
              </div>
            </div>
            <el-tooltip
                    v-show="mima_login_tab1==1"
                    class="item"
                    effect="dark"
                    content="看不清，换一张"
                    placement="top"
            >
              <img :src="yzmImg" class="yzm" @click="changeSrc" />
            </el-tooltip>

            <div v-show="mima_login_tab1==2">
              <div>
                <img src="@/assets/images/foogetPassword/dianhua.png" />
              </div>
              <div>
                <input type="text" placeholder="手机号" />
              </div>
            </div>

            <div style="width: 200px;" v-show="mima_login_tab1==2">
              <div>
                <img src="@/assets/images/anquan.png" />
              </div>
              <div>
                <input type="text" placeholder="短信验证码" style="width: 120px;" />
              </div>
            </div>

            <button class="yzm djhq" v-show="mima_login_tab1==2">点击获取</button>

            <p class="wjmm">
              <span @click="showFogetPassword">忘记密码？</span>
            </p>

            <!--                        <button class="btn_login" :style="mima_login_tab1==2?{marginTop:'80px'}:{}" @click="login">登录</button>-->
            <el-button
                    type="primary"
                    class="btn_login"
                    v-show="LoginLoading"
                    @click="login"
            >登录</el-button>
            <el-button
                    type="primary"
                    style="margin-left: 0px;"
                    :loading="true"
                    v-show="!LoginLoading"
                    class="btn_login"
            >登录中</el-button>
          </div>
          <!-- 忘记密码 -->
          <div class="login_right" v-show="!ifShowPasswordLogin">
            <h5 style="padding-left:129px;margin: 33px 0 16px;">忘记密码</h5>
            <div class="login_tab">
              <div :class="wjmmIndex==1?'active_login_tab':''" @click="wjmm_tab(1)">
                <span>1</span>
                <span>基本信息</span>
              </div>
              <div @click="wjmm_tab(2)" :class="wjmmIndex==2?'active_login_tab':''">
                <span>2</span>
                <span>短信验证</span>
              </div>
              <div @click="wjmm_tab(3)" :class="wjmmIndex==3?'active_login_tab':''">
                <span>3</span>
                <span>设置成功</span>
              </div>
            </div>
            <!-- 基本信息 -->
            <template>
              <div v-show="wjmmIndex==1" style="margin-bottom: 18px;height: 36px;">
                <div
                        style="background:rgba(150,194,236,0.27);border-right: 1px solid rgba(150,194,236,0.27);"
                >
                  <img src="@/assets/images/foogetPassword/tongyong.png" style="width: 14px;" />
                </div>
                <div>
                  <input type="text" placeholder="用户名" style="padding-left: 15px;width: 262px;" />
                </div>
              </div>
              <div v-show="wjmmIndex==1" style="margin-bottom: 18px;height: 36px;">
                <div
                        style="background:rgba(150,194,236,0.27);border-right: 1px solid rgba(150,194,236,0.27);"
                >
                  <img src="@/assets/images/foogetPassword/mima.png" style="width: 14px;" />
                </div>
                <div>
                  <input
                          type="password"
                          placeholder="新登录密码"
                          style="padding-left: 15px;width: 262px;"
                  />
                </div>
              </div>

              <div v-show="wjmmIndex==1" style="margin-bottom: 18px;height: 36px;">
                <div
                        style="background:rgba(150,194,236,0.27);border-right: 1px solid rgba(150,194,236,0.27);"
                >
                  <img src="@/assets/images/foogetPassword/mima-2.png" style="width: 14px;" />
                </div>
                <div>
                  <input type="password" placeholder="确认登录密码" style="padding-left: 15px;width: 262px;" />
                </div>
              </div>
            </template>
            <!-- 短信验证 -->
            <template>
              <div v-show="wjmmIndex==2" style="margin-bottom: 18px;height: 36px;">
                <div
                        style="background:rgba(150,194,236,0.27);border-right: 1px solid rgba(150,194,236,0.27);"
                >
                  <img src="@/assets/images/foogetPassword/dianhua.png" style="width: 14px;" />
                </div>
                <div>
                  <input type="text" placeholder="手机号" style="padding-left: 15px;width: 262px;" />
                </div>
              </div>

              <div v-show="wjmmIndex==2" style="width: 220px;height: 36px;">
                <div
                        style="background:rgba(150,194,236,0.27);border-right: 1px solid rgba(150,194,236,0.27);"
                >
                  <img src="@/assets/images/anquan.png" />
                </div>
                <div>
                  <input type="text" placeholder="短信验证码" style="width: 100px;padding-left: 15px;" />
                </div>
              </div>
              <div
                      v-show="wjmmIndex==2"
                      style="width: 90px;height: 36px;background: #3F82E7;color: #fff;line-height: 36px;justify-content: center;position: absolute;right: 56px;top: 189px;"
              >点击获取</div>
            </template>
            <template>
              <img
                      v-show="wjmmIndex==3"
                      src="@/assets/images/foogetPassword/miamasuo.png"
                      alt
                      style="margin: 39px 0 24px 65px;display: block;"
              />
              <p
                      @click="showFogetPassword"
                      v-show="wjmmIndex==3"
                      style="text-align: center;padding-right: 56px;"
              >
                设置完成，去
                <span style="color: #3F82E7;cursor: pointer;">登录</span>
              </p>
            </template>
            <p
                    class="wjmm"
                    v-show="wjmmIndex!=3"
                    :style="wjmmIndex==2?{marginBottom:'88px'}:{}"
                    @click="showFogetPassword"
            >
              <span>已有账号，马上登录</span>
            </p>

            <button class="btn_login" v-show="wjmmIndex!=3">{{showRegister==true?'登录':'下一步'}}</button>
          </div>
        </el-col>
        <!--显示注册-->
        <el-col :span="21" v-show="showRegister">
          <!--                    注册组件-->
          <register @showRegisterMethods="showLogin" :aa="bb" v-show="JiGouRegister"></register>
          <YZRegister @showRegisterMethods="showLogin" v-show="!JiGouRegister && !showRegisterState && !showYzQuery"></YZRegister>
          <OrgState @showRegisterMethods="showLogin"   v-show="showRegisterState"></OrgState>
          <YZQuery @showRegisterMethods="showLogin" v-show="showYzQuery"></YZQuery>
        </el-col>
      </el-row>

      <!-- 展示图片 -->
      <div>
        <img src="@/assets/images/banner.png" />
      </div>
      <!-- 网上办事 -->
      <div class="wsbs">
        <div class="wsbs_title">网上办事</div>
        <el-row class="wsbs_content" type="flex" justify="space-between">
          <el-col :span="3">
            <div class="wsbs_left wsbs_style">
              <img src="@/assets/images/denglu.png" />
              <p>一网通办</p>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="wsbs_center1">
              <p @click="toRegister">
                <img src="@/assets/images/diannao.png" />
                <span>机构注册</span>
              </p>
              <p @click="toRegisterSate">
                <img src="@/assets/images/fangdajing.png" />
                <span>注册状态查询</span>
              </p>
              <p>
                <img src="@/assets/images/zhishi.png" />
                <span>办事指南</span>
              </p>
              <p>
                <img src="@/assets/images/fangdajing.png" />
                <span>状态反馈</span>
              </p>
            </div>
          </el-col>
          <el-col :span="13">
            <div class="wsbs_center2">
              <p>
                业主维修资金查询
                <span @click="query">（立即查询）</span>
              </p>
              <p>
                《住宅专项维修资金管理办法》
                <span @click="downLoad">下载</span>
              </p>
              <p>
                业主维修资金查询
                <span>（立即查询）</span>
              </p>
              <p>
                《住宅专项维修资金管理办法》
                <span>下载</span>
              </p>
            </div>
          </el-col>
          <el-col :span="3">
            <div class="wsbs_left" style="margin-left: 10px;">
              <img src="@/assets/images/item353.png" />
              <p>住宅服务</p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
  import Swiper from "swiper";
  import register from "./register";
  import YZRegister from "./YZRegister";
  import OrgState from "./OrgState";
  import YZQuery from "./YZQuery";
  import {JSEncrypt} from 'jsencrypt';
  import MathUuid from "../../assets/js/Math.uuid";
  import qs from "qs";
  import urljson from "../../static/json/url.json";

  export default {
    name: "index",
    components: {
      register,
      YZRegister,
      OrgState,
      YZQuery
    },
    data() {
      return {
        JiGouRegister: true, //显示机构注册
        LoginLoading: true, //显示登录中
        url: this.$Config.base_server,
        showRegister: false, //控制显示隐藏
        showRegisterState: false,
        showYzQuery:false,
        index: 1,
        bb: 1,
        mima_login_tab1: 1,
        timer: "", //定义一个定时器的变量
        wjmmIndex: 1, //控制忘记密码
        ifShowPasswordLogin: true,
        yzmImg: "", //验证码
        loginParams: {
          userName: "",
          password: "",
          yzm: ""
        },
        sortNum: "", //随机数
        client_id: "webApp_hx",
        loginClass: "back_user",
        count: 0, //记录点击登录按钮的次数 超过3次 需要等待一分钟
        loginDisabled: false
      };
    },
    created() {
      // //监听enter事件
       const _this=this;
       document.onkeydown = function (e) {
         var key = window.event.keyCode;
          if (key == 13) {
              _this.login();
          }
      }


    },
    mounted() {
      const that = this;
      localStorage.removeItem("client_id");
      if (localStorage.getItem("token") == "" || localStorage.getItem("token") == null) {
        let urldata=urljson;
        var url = window.location.href;
        //循环
        urldata.forEach((value,key)=>{
          if (url.indexOf(value.key)>=0){
            that.client_id=value.client_id;
            return false;
          }
        });
        localStorage.setItem("client_id", that.client_id);
      }
      if (localStorage.getItem("token")) {
        this.$router.push({ name: "table" });
      }

      let deviceId = Math.uuid();
      this.sortNum = deviceId;
      this.yzmImg = this.url + "/api-auth/validata/code/" + deviceId;
      //轮播组件
      const mySwiper = new Swiper(".swiper-container", {
        autoplay: 3000,
        loop: true,
        paginationClickable: true,
        pagination: ".swiper-pagination",
        autoplayDisableOnInteraction: false
      });
    },
    methods: {
      login() {

        if (this.mima_login_tab1 == 1) {
          //密码登录
          this.mima_denglu();
        } else {
          //短信
          this.duanxing_denglu();
        }

      },
      mima_denglu() {
        const that = this;
        //判断输入用户名
        if (this.loginParams.userName == "") {
          that.$message({
            showClose: true,
            message: "请输入用户名",
            type: "warning"
          });

          return;
        }
        //判断输入密码
        if (this.loginParams.password == "") {
          that.$message({
            showClose: true,
            message: "请输入密码",
            type: "warning"
          });
          return;
        }
        //判断输入验证码
        if (this.loginParams.validCode == "") {
          that.$message({
            showClose: true,
            message: "请输入验证码",
            type: "warning"
          });
          return;
        }
        this.LoginLoading = false;

        const data = {
          grant_type: "password",
          scope: "app",
          client_id: this.client_id,
          client_secret: "webApp",
          auth_type: that.loginClass,
          username: this.loginParams.userName,
          password: this.passwordEncryption(this.loginParams.password),
          validCode: this.loginParams.yzm,
          deviceId: this.sortNum
        };
        //ajax 请求
        $.ajax({
          url: this.url + "/api-auth/oauth/token",
          data: data,
          type: "POST",
          success: function(res) {
            if (res.access_token) {
              that.$addStorageEvent(2, "token", res.access_token);
              localStorage.setItem("token", res.access_token);
              that.$message({
                showClose: true,
                message: "恭喜你，登录成功",
                type: "success"
              });
              that.getUserMessage(res.access_token);
              setTimeout(() => {
                if (JSON.parse(localStorage.getItem("user")).type == "0") {
                  //物业
                  that.$router.push({ name: "proVillage" });
                } else if (JSON.parse(localStorage.getItem("user")).type == "3") {
                  that.$router.push({
                    name: "ownerVillage",
                    params: { userId: 123 }
                  });
                } else if (JSON.parse(localStorage.getItem("user")).type == "2") {
                  //业主委员会
                  that.$router.push({
                    name: "hoaVillage",
                    params: { userId: 123 }
                  });
                } else if (JSON.parse(localStorage.getItem("user")).type == "1") {
                  //开发商
                  that.$router.push({ name: "table", params: { userId: 123 } });
                }
              }, 1000);
            }
            that.LoginLoading = true;
          },
          error: function(res) {
            that.LoginLoading = true;

            that.changeSrc(); //刷新验证码
            that.$message({
              showClose: true,
              message: res.responseJSON.resp_msg,
              type: "error"
            });
          }
        });
      },
      passwordEncryption(passwordUser) {
        let publicKey = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAwnsa+VJwLZ71scTYK8kb\n' +
            'zuYGX+oi54imvh1wasBeBOEyMTP4JxilCY0P0dEA/quZrJIE+Rvx3NlXN7by5neu\n' +
            '5nxAnentdm1ApRwbImlirNvbrVBQ/ga+AIaP4Xn4acD8x+5nXzSM3HGvIogpymbN\n' +
            'z36PPhGjhV6WnIIfBoGXCqr6edenSm6uCKceuHM/ISjzjsMWcqZl7j4qSbWP7R6P\n' +
            'qhnUaFQ3Sx8+dbAgYI0NwLMPtlJu67YoaU2mXA3isJD0inQfZBynEPMv4sCu39nH\n' +
            'jMkZejqJGiIJj1ZZ/FDr393tUBxlcnYme/xVwwa4LzVutjM6qr9AJGTXMFxWXTUp\n' +
            'RQIDAQAB' // 从后台获取公钥，这个保存一下，在这里用。
        console.log('后台公钥---------> ', publicKey)
        let encryptor = new JSEncrypt()  // 新建JSEncrypt对象
        encryptor.setPublicKey(publicKey)  // 设置公钥
        let passwordEncryp = encryptor.encrypt(passwordUser)  // 对密码进行加密
        return passwordEncryp
      },
      getUserMessage(access_token) {
        const that = this;
        axios
                .post(this.url + "/api-auth/oauth/userinfo?access_token="+access_token, {})
                .then(function(res) {
                  if (res.status == 200) {
                    that.$addStorageEvent(2, "user", JSON.stringify(res.data.user));
                    localStorage.setItem("user", JSON.stringify(res.data.user));
                  }
                })
                .catch(function(err) {
                  console.log("LOGIN_" + err);
                });
      },
      duanxing_denglu() {},
      changeSrc() {
        this.loginParams.yzm = ""; //清空验证码输入框
        const deviceId = Math.uuid();
        this.sortNum = deviceId;
        this.yzmImg = this.url + "/api-auth/validata/code/" + deviceId;
      },
      mima_login_tab(index) {
        this.mima_login_tab1 = index;
        if (index == 3) {
          //业主注册
          this.showRegister = true;
          this.JiGouRegister = false; //显示物业注册
        }
      },
      showFogetPassword() {
        this.ifShowPasswordLogin = !this.ifShowPasswordLogin;
      },
      wjmm_tab(index) {
        //忘记密码tab
        this.wjmmIndex = index;
      },
      showLogin(state) {
        this.showRegister = state;
        this.mima_login_tab1 = 1;
      },
      tab(index) {
        this.showRegister = false; //控制隐藏注册
        this.mima_login_tab1 = 1; //控制到密码登录
        this.index = index; //改变tab页面
        if (index == 1) {
          //开发商登录
          this.loginClass = "back_user";
        } else if (index == 2) {
          //物业登录
          this.loginClass = "back_user";
        } else if (index == 3) {
          //业主委员会登录
          this.loginClass = "back_user";
        } else {
          //业主登录
          this.loginClass = "back_owner";
        }
        localStorage.setItem("sf", index); //记录当前用户的身份识别 1开发商 2 物业
        this.changeSrc(); // 刷新二维码
      },
      toRegister() {
        this.bb = 1;
        this.showRegister = true;
        this.JiGouRegister = true; //显示物业注册
        this.showRegisterState=false;
        this.showYzQuery=false;
      },
      toRegisterSate(){
        this.showRegister = true;
        this.JiGouRegister = false; //显示物业注册
        this.showRegisterState=true;
        this.showYzQuery=false;
      },
      query(){
        this.showYzQuery=true;
        this.showRegister = true;
        this.JiGouRegister = false; //显示物业注册
        this.showRegisterState=false;

      },
      downLoad(){
        //下载住宅专项维修资金管理办法
        window.location.href = this.url + "/api-public/yzQuery/exportDocument";
      }
    }
  };
</script>

<style scoped lang="scss">
  @import "../../../node_modules/swiper/dist/css/swiper.css";
  @import "../../assets/css/index/index.css";
</style>