<template>
    <div>
        <div class="register">
            <h4>[ 业主注册 ]</h4>
            <div class="four_stap" style="opacity: 0">
                <div>
                    <div :class="registerShow>0?'active_bg':''">1</div>
                    <p>选择用户信息</p>
                </div>
                <div>
                    <div :class="registerShow>1?'active_bg':''">2</div>
                    <p>填写账户信息</p>
                </div>
                <div>
                    <div :class="registerShow>2?'active_bg':''">3</div>
                    <p>验证账户信息</p>
                </div>
                <div>
                    <div :class="registerShow>3?'active_bg':''">4</div>
                    <p>注册成功</p>
                </div>
            </div>
            <!-- 第一步 -->
            <div class="stap1_biaodan">
                <div>
                    <el-form ref="form" :model="form" label-width="130px">
                        <el-form-item label="业主姓名：">
                            <el-input  v-model="form.name"
                                      placeholder="请输入业主姓名" size="mini"></el-input>
                        </el-form-item>


                        <el-form-item label="联系电话：">
                            <el-input v-model="form.phone" onkeyup="value=value.replace(/[\u4e00-\u9fa5]/ig,'')" placeholder="请输入联系电话" size="mini"></el-input>
                        </el-form-item>

                        <el-form-item label="短信验证码：">
                            <el-input @input="checkMobile" onkeyup="value=value.replace(/[\u4e00-\u9fa5]/ig,'')"
                                      v-model="form.yzm" placeholder="请输入短信验证码" size="mini"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="step2_right">
                    <el-form ref="form" :model="form" label-width="130px" style="margin-left: -30px">
                        <el-form-item label="身份证号：">
                            <el-input v-model="form.selfCard" placeholder="请输入身份证号" size="mini"></el-input>
                        </el-form-item>
                        <el-form-item label="电子邮箱：">
                            <el-input onkeyup="value=value.replace(/[\u4e00-\u9fa5]/ig,'')" v-model="form.dEmail"
                                      placeholder="请输入电子邮箱" size="mini"></el-input>
                        </el-form-item>

                        <el-form-item label="密码：">
                            <el-input type="password" v-model="form.pass" placeholder="请输入秘密" size="mini"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
            </div>

            <div class="prvOrNext" >
                <button @click.prevent="getYzm" v-show="show">获取验证码</button>
                <button @click.prevent="getYzm" v-show="!show">重新发送（{{count}}）</button>
                <button @click.prevent="register">注册</button>
            </div>
            <p class="alread_name">已有账户，直接<span @click="toLogin"
                                                class="toLoginSmall">登录</span>
            </p>
        </div>
    </div>
</template>

<script>
    import MathUuid from "../../assets/js/Math.uuid"

    export default {
        name: "register",
        data() {
            return {
                url: this.$Config.base_server,
                show: true,
                count: '',
                TIME_COUNT: 60,//验证码倒计时60s
                registerShow: 1,
                form: {
                    name:'',//业主姓名
                    phone:'',//联系电话
                    yzm:'',//验证码
                    selfCard:'',//身份证号
                    dEmail:'',//电子邮箱
                    pass:'',//密码
                },
                jglxList: [],
                key: '',
            }
        },
        mounted() {
            this.jigouClass();
        },
        methods: {
            checkMobile() {
                this.form1.data4 = this.form1.data4.replace(/[^\d]/g, '')
            },
            // 验证码倒计时60s
            getCode(formData) {
                if (!this.timer) {
                    this.count = this.TIME_COUNT;
                    this.show = false;
                    this.timer = setInterval(() => {
                        if (this.count > 0 && this.count <= this.TIME_COUNT) {
                            this.count--;
                        } else {
                            this.show = true;
                            clearInterval(this.timer);
                            this.timer = null;
                        }
                    }, 1000)
                }
            },
            getYzm() {//获取验证码
                if(this.form.phone==''){
                    this.$message({
                        message: '请输入手机号',
                        type: 'warning'
                    });
                    return
                }
                console.log(this.form.phone.length);
                if(this.form.phone.length>11){
                    this.$message({
                        message: '请输入正确的手机号',
                        type: 'warning'
                    });
                    return;
                }
                this.getCode();
                const that = this;
                const client_id = localStorage.getItem("client_id");
                // +this.form3.data1
                $.post(this.url + "/api-sms/sms-internal/codes?phone=" + this.form.phone+"&client_id="+client_id, {}, function (res) {
                    that.key = res.key;
                });
            },
            register() {
                if(this.validateForm()==false){
                    return;
                }
                const that = this;
                const data={
                    ownerName:this.form.name,
                    idCard:this.form.selfCard,
                    password:this.form.pass,
                    phone:this.form.phone,
                    mail:this.form.dEmail,
                    smsCode:this.form.yzm,
                    key:this.key,
                    client_id:localStorage.getItem("client_id")
                }
                $.ajax({
                    url: this.url + "/api-public/common/register/owner?client_id="+client_id,
                    contentType: 'application/json;charset=utf8',
                    data: JSON.stringify(data),
                    type: 'post',
                    success: function (result) {

                        if (result.resp_code == 0) { //注册成功
                            that.$message({
                                showClose: true,
                                message: '恭喜你，注册成功',
                                type: 'success'
                            });
                            that.toLogin();

                        } else {
                            that.$message({
                                showClose: true,
                                message: result.resp_msg,
                                type: 'error'
                            });
                        }

                    },
                    error:function (res) {

                    }
                });

            },
            validateForm(){
                if(this.form.name==''){
                    this.$message({
                        showClose: true,
                        message: '请您输入业主姓名',
                        type: 'warning'
                    });
                    return false;
                }else if(this.form.phone==''){
                    this.$message({
                        showClose: true,
                        message: '请您输入联系电话',
                        type: 'warning'
                    });
                    return false;
                }else if(this.form.yzm==''){
                    this.$message({
                        showClose: true,
                        message: '请您输入验证码',
                        type: 'warning'
                    });
                    return;
                }else if(this.form.selfCard==''){
                    this.$message({
                        showClose: true,
                        message: '请您输入身份证号',
                        type: 'warning'
                    });
                    return false;
                }else if(this.form.dEmail==''){
                    this.$message({
                        showClose: true,
                        message: '请您输入电子邮箱',
                        type: 'warning'
                    });
                    return false;
                }else if(this.form.pass==''){
                    this.$message({
                        showClose: true,
                        message: '请输入密码',
                        type: 'warning'
                    });
                    return false;
                }
                return true;
            },
            toLogin() {
                this.$emit("showRegisterMethods", false)
                //
            },jigouClass() {
                const that = this;
                const  client_id=localStorage.getItem("client_id");
                const data = {
                    access_token: localStorage.getItem("token")
                }
                $.get(this.url + "/api-public/sysdict/list?key=org_type?client_id="+client_id, {}, function (res) {

                    that.jglxList = res;
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    .el-input, .el-select {
        width: 200px;
    }

    .stap3_button {
        position: relative;

        button {
            position: absolute;
            top: 53px;
            right: 275px;
            width: 100px;
            height: 27px;
            font-size: 13px;
            color: #96C2EC;
            border: 1px solid #96C2EC;
            outline: none;
            background: none;
            cursor: pointer;
        }
    }

    .el-form-item {
        margin-bottom: 5px;
    }
</style>
