<template>
    <div>
        <div class="register">
            <h4>[ 机构注册状态查询 ]</h4>
            <div v-show="showorgCodediv==true">
                <el-row style="display: block;margin-left: auto;margin-right: auto;margin-top: 100px;width: 600px">
                    <el-input  style="width: 500px" v-model="orgcode" placeholder="请输入组织机构代码或统一社会信用代码">
                    </el-input>
                    <el-button type="primary"  @click="findOrgState">立即查询</el-button>
                </el-row>
            </div>

            <div v-show="showOrgtable==true">
                <el-table :data="OrgtableData"  border style="width: 100%">
                    <el-table-column
                            prop="orgCard"
                            label="统一社会信用代码"
                            width="180">
                    </el-table-column>

                    <el-table-column
                            prop="orgName"
                            label="机构名称"
                            width="180">
                    </el-table-column>

                    <el-table-column
                            prop="legPerson"
                            label="法人代表"
                            width="150">
                    </el-table-column>

                    <el-table-column
                            prop="type"
                            label="机构类型"
                            width="120"
                            :formatter="formatterorg"
                          >
                    </el-table-column>

                    <el-table-column
                            prop="phone"
                            label="联系电话"
                            width="120">
                    </el-table-column>

                    <el-table-column
                            prop="busAddress"
                            label="办公地址"
                            width="200">
                    </el-table-column>


                    <el-table-column
                            fixed="right"
                            label="操作"
                            width="100">
                        <template slot-scope="scope">
                            <el-button @click="handleClick(scope.row)" type="primary" size="mini">查看进度</el-button>
                        </template>
                    </el-table-column>
                </el-table>

                  <div>
                      <el-button type="primary" @click="goback" style="display:block;margin:0 auto;margin-top: 20px">返回</el-button>
                  </div>
            </div>

            <el-dialog
                    title="身份信息验证"
                    :visible.sync="orgusercenterDialogVisible"
                    width="640px"
                    center>
                <hr/>
                <span style="text-align: center; margin-top:10px;display: block">请输入注册时填写的手机号进行身份验证</span>
                <br/>
                <span style="text-align: center; margin-top:36px;display: block">
                    <el-input v-model="phone" style="width: 200px;margin: 0 auto;"  placeholder="请输入注册的手机号码验证"></el-input>
                </span>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="checkOrgPhone">验证查询</el-button>
                    <el-button @click="orgusercenterDialogVisible = false">取消</el-button>
                </span>
            </el-dialog>

            <div v-show="showprocessdiv">

                <el-steps :active="active" align-center>
                    <el-step :title="item.taskName"  v-for="item  in instanceList" ></el-step>
                </el-steps>

                <el-form ref="form" :model="form">
                      <el-form-item>
                              <el-col :span="6" v-show="active>=1"  style="margin-left:10%;">
                                  <el-form-item label="审批人">
                                      <span>{{shenpi1.dealName}}</span>
                                  </el-form-item>
                                  <el-form-item label="审批结果">
                                      <span v-if="shenpi1.appAction==1">同意</span>
                                      <span v-if="shenpi1.appAction==2">反对</span>
                                      <span v-if="shenpi1.appAction==3">弃权</span>
                                      <span v-if="shenpi1.appAction==4">驳回</span>
                                      <span v-if="shenpi1.appAction==''">转办</span>
                                  </el-form-item>
                                  <el-form-item label="审批意见">
                                      <span>{{shenpi1.appOpinion}}</span>
                                  </el-form-item>
                              </el-col>
                             <el-col :span="6" v-show="active>=2" style="margin-left: 10%;">
                                  <el-form-item label="审批人">
                                      <span>{{shenpi2.dealName}}</span>
                                  </el-form-item>
                                  <el-form-item label="审批结果">
                                      <span v-if="shenpi2.appAction==1">同意</span>
                                      <span v-if="shenpi2.appAction==2">反对</span>
                                      <span v-if="shenpi2.appAction==3">弃权</span>
                                      <span v-if="shenpi2.appAction==4">驳回</span>
                                      <span v-if="shenpi2.appAction==''">转办</span>
                                  </el-form-item>
                                  <el-form-item label="审批意见">
                                      <span>{{shenpi2.appOpinion}}</span>
                                  </el-form-item>
                              </el-col>
                             <el-col :span="6" v-show="active>=3" style="margin-left: 5%;">
                                  <el-form-item label="审批人">
                                      <span>{{shenpi3.dealName}}</span>
                                  </el-form-item>
                                  <el-form-item label="审批结果">
                                      <span v-if="shenpi3.appAction==1">同意</span>
                                      <span v-if="shenpi3.appAction==2">反对</span>
                                      <span v-if="shenpi3.appAction==3">弃权</span>
                                      <span v-if="shenpi3.appAction==4">驳回</span>
                                      <span v-if="shenpi3.appAction==''">转办</span>
                                  </el-form-item>
                                  <el-form-item label="审批意见">
                                      <span>{{shenpi3.appOpinion}}</span>
                                  </el-form-item>
                             </el-col>
                      </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
    import MathUuid from "../../assets/js/Math.uuid"

    export default {
        name: "OrgState",
        data() {
            return {
                url: this.$Config.base_server,
                show: true,
                active:1,
                orgusercenterDialogVisible:false,
                showOrgtable:false,
                showorgCodediv:true,
                showprocessdiv:false,
                instanceList:[],
                shenpi1:{},
                shenpi2:{},
                shenpi3:{},
                count: '',
                phone:'',
                orgcode:'',
                phonelist:[],
                instance_id:'',
                phonecode4:'',
                phonecode5:'',
                phonecode6:'',
                phonecode7:'',
                TIME_COUNT: 60,//验证码倒计时60s
                registerShow: 1,
                OrgtableData: [],
                form: {
                    name: ''
                },
                jglxList: [],
                key: '',
            }
        },
        mounted() {
           this.jigouClass();
        },
        methods: {
            checkOrgPhone() {
                this.findorgProcess();
            },
            findorgProcess(){
                const that=this;
                const  phone=this.phone;
                const client_id=localStorage.getItem("client_id");
                if (that.phonelist.indexOf(phone) >-1){
                    $.ajax({
                        url: this.url+'/api-public/common/findOrgState?instance_id='+this.instance_id+'&client_id='+client_id,
                        type:'get',
                        success:function (res) {
                           if (res!=null){
                               that.showorgCodediv=false;
                               that.showOrgtable=false;
                               that.orgusercenterDialogVisible=false;
                               that.showprocessdiv=true;
                               that.instanceList=res;
                               that.shenpi1 = res[0];
                               if(res.length==2){
                                   that.shenpi2=res[1];
                               }
                               if(res.length==3){
                                   that.shenpi2=res[1];
                                   that.shenpi3=res[2];
                               }
                               that.active=res.length;
                           }else{
                               that.$message({
                                   showClose: true,
                                   message: '流程查询信息失败',
                                   type: 'error'
                               });
                               return false;
                           }
                        },
                        error:function () {
                            that.$message({
                                showClose: true,
                                message: '流程查询信息失败',
                                type: 'error'
                            });
                            return false;
                        }
                    });

                }else{
                    this.$message({
                        showClose: true,
                        message: '手机号码验证失败！',
                        type: 'error'
                    });
                    return false;
                }
            },
            findOrgState(){
                const  orgcode=this.orgcode;
                const  that=this;
                const  array=[];
                const  client_id=localStorage.getItem("client_id");
                if (orgcode==''){
                    this.$message({
                        showClose: true,
                        message: '统一社会信用代码或组织机构代码不能为空',
                        type: 'warning'
                    });
                    return false;
                }
                $.ajax( {
                    url: this.url+'/api-public/common/findOrgByOrgCode?orgcode='+orgcode+'&client_id='+client_id,
                    type:'get',
                    success:function (res) {
                        if (res.resp_code == 0){
                            array.push(res.datas);
                            that.OrgtableData =array;
                            that.showorgCodediv = false;
                            that.showOrgtable = true;
                        }else {
                            that.$message({
                                showClose: true,
                                message: '查询失败',
                                type: 'error'
                            });
                        }
                    },
                    error:function () {
                        that.$message({
                            showClose: true,
                            message: '查询失败',
                            type: 'error'
                        });
                    }
                });
            },
            formatterorg(row, column) {
               if (row.type==1){
                   return '开发商'
               }
               return '物业';
            },

            handleClick(row){
                const that=this;
                const client_id=localStorage.getItem("client_id");
                that.orgusercenterDialogVisible=true;
                that.instance_id=row.activitiId;
                $.ajax({
                    url:this.url+'/api-public/common/findOrgUserPhoneByOrgId?orgId='+row.orgId+'&client_id='+client_id,
                    type:'get',
                    success:function(res){
                        that.phonelist=res;
                    },
                    error:function(){
                        this.$message({
                            showClose: true,
                            message: '手机号码查询失败',
                            type: 'error'
                        });
                    }

                });

            },
            goback(){//返回
                this.showOrgtable=false;
                this.showorgCodediv=true;
            },
            validateForm1() {
                if (this.form1.data1 == '') {
                    this.$message({
                        showClose: true,
                        message: '请您输入统一社会信用代码',
                        type: 'warning'
                    });
                    return false;
                } else if (!this.enterCodeCheck(this.form1.data1)) {
                    this.$message({
                        showClose: true,
                        message: '请输入正确的统一社会信用代码',
                        type: 'warning'
                    });
                    return false;
                }
                if (this.form1.data2 == '') {
                    this.$message({
                        showClose: true,
                        message: '请您输入机构名称',
                        type: 'warning'
                    });
                    return false;
                } else if (this.form1.data3 == '') {
                    this.$message({
                        showClose: true,
                        message: '请您输入法定代表人',
                        type: 'warning'
                    });
                    return false;
                } else if (this.form1.data4 == '') {
                    this.$message({
                        showClose: true,
                        message: '请您输入联系电话',
                        type: 'warning'
                    });
                    return;
                } else if (this.form1.data5 == '') {
                    this.$message({
                        showClose: true,
                        message: '请您输入办公地址',
                        type: 'warning'
                    });
                    return false;
                } else if (this.form1.data6 == '') {
                    this.$message({
                        showClose: true,
                        message: '请您输入邮箱',
                        type: 'warning'
                    });
                    return false;
                } else if (this.form1.data7 == '') {
                    this.$message({
                        showClose: true,
                        message: '请您选择成立时间',
                        type: 'warning'
                    });
                    return false;
                } else if (this.form1.data8 == '') {
                    this.$message({
                        showClose: true,
                        message: '请您选择机构类型',
                        type: 'warning'
                    });
                    return false;
                }
                return true;
            },
            validateForm2() {
                if (this.form2.data1 == '') {
                    this.$message({
                        showClose: true,
                        message: '请您输入用户名',
                        type: 'warning'
                    });
                    return false;
                } else if (this.form2.data2 == '') {
                    this.$message({
                        showClose: true,
                        message: '请您输入密码',
                        type: 'warning'
                    });
                    return false;
                } else if (this.form2.data3 == '') {
                    this.$message({
                        showClose: true,
                        message: '请您输入姓名',
                        type: 'warning'
                    });
                    return false;
                }
                return true;
            },
            validateForm3() {
                if (this.form3.data1 == '') {
                    this.$message({
                        showClose: true,
                        message: '请您输入联系电话',
                        type: 'warning'
                    });
                    return false;
                } else if (this.form3.data2 == '') {
                    this.$message({
                        showClose: true,
                        message: '请您输入短信验证码',
                        type: 'warning'
                    });
                    return false;
                }
                return true;
            },
            toLogin() {
                this.$emit("showRegisterMethods", false)
                //
            }
        }
    }
</script>

<style scoped lang="scss">
    .el-input, .el-select {
        width: 200px;
    }

    .stap3_button {
        position: relative;

        button {
            position: absolute;
            top: 53px;
            right: 275px;
            width: 100px;
            height: 27px;
            font-size: 13px;
            color: #4DA5E7;
            border: 1px solid #4DA5E7;
            outline: none;
            background: none;
            cursor: pointer;
        }
    }

    .el-form-item {
        margin-bottom: 5px;
    }
</style>